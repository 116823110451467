import PlacesAutocomplete from 'stimulus-places-autocomplete'

export default class extends PlacesAutocomplete {


  setAddressComponents(addressComponents) {
    super.setAddressComponents(addressComponents);
    this.element.dispatchEvent(
      new CustomEvent("place_changed", {
        bubbles: true,
       
      })
    )
  }


  formatAddressComponents (addressComponents) {
    const data = {}

    addressComponents.forEach((component) => {
      const type = component.types[0];
      data[type] = component.short_name;
    });

    return data;
  }

  initAutocomplete() {
    switch(this.data.get("input")) {
      case 'address':
        this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, this.autocompleteOptions);
        break;
      case 'city':
        this.autocomplete = new google.maps.places.Autocomplete(this.cityTarget, this.autocompleteOptions);
        break;
      default:
        this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, this.autocompleteOptions);
    }

    this.autocomplete.addListener('place_changed', this.placeChanged)
  }
 
}