import { Controller } from "@hotwired/stimulus";
import Swal from 'sweetalert2'

export default class SweetAlert extends Controller {

  showConfirm(event) {
    if (this.element.getAttribute('data-confirm-swal') === null) return;
    else {
      event.stopImmediatePropagation()
      event.preventDefault();
      var title, text;
      title = this.element.getAttribute('data-confirm-swal') || i18n.t('are_you_sure');
      text = this.element.getAttribute('data-text');
      Swal.fire({
        title: title,
        text: text || '',
        showCancelButton: true,
        confirmButtonText: i18n.t('yes'),
        cancelButtonText: i18n.t('cancel')
      }).then((result) => {
        if (result.isConfirmed) {
          event.target.dispatchEvent(new Event('confirm-swal'));
          this.element.removeAttribute('data-confirm-swal');;
          this.element.click()
        
        }
        else {
          
        }
      });
    }
  }

}