const itemIncludeVal = (item, val, keys) => {
  let isToReturn = false;
  const keyToSearch = keys || Object.eys(item);
  keyToSearch.forEach( key => {
    if (item[key] && item[key].toString().toLowerCase().includes(val)) {
      isToReturn = true;
    }
  });
  return isToReturn;
};

export const arrayFiltered = (value, data, keys) => {
  // console.log('value', value)
  // console.log('data', data)
  // console.log('keys', keys)
  const valueLower = value.toLowerCase();
  const filtered = data.filter(item => itemIncludeVal(item, valueLower, keys));
  return filtered;
};

export const create_UUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

export const sortByKey = (array, key) => {
  try {
    return array.sort(function (a, b) {
      var x = a[key] || '';
      var y = b[key] || '';
      if (x && typeof x === 'string') { x = x.toLowerCase(); }
      if (y && typeof y === 'string') { y = y.toLowerCase(); }
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }
  catch (e) {
	  console.error(e);
  }
}

export const getFormElements = $element => {
  var all_inputs = $element.find('input:not([type=hidden]):not(.date), select, textarea, [type="hidden"].date')
  var nested_form_inputs = $element.find(".additem-nested-form > input").siblings().find('input:not([type=hidden]):not(.date), select, textarea, [type="hidden"].date')
  all_inputs = all_inputs.toArray().filter((el) => !nested_form_inputs.toArray().includes(el));
  return all_inputs;
}

export const getIsCheckboxOrRadio = function($e) {
  let IsCheckboxOrRadio = false;
  $e.each(function() {
    const type = $(this).attr('type');
    if ((type === 'radio') || (type === 'checkbox')) { return IsCheckboxOrRadio = true; }
  });
  return IsCheckboxOrRadio;
};