const ResetRadio = () => {
  const radioValues = {};

  window.enableRadioReset = true

  const events = ($item) => {
    $item.on('resetRadioDelete', function() {
      const name = $(this).attr('name');
      delete radioValues[name];
    })
  }

  const radioClicked = ($clicked) => {
    if (!window.enableRadioReset) {
      return;
    }
    const name = $clicked.attr('name');
    const clickedValue = $clicked.val();
    if (clickedValue === radioValues[name]) {
      // need timeout for rails-ujs
      setTimeout(function() {
        $clicked.prop('checked', false).trigger('change');
        $clicked.trigger('resetRadioDelete')
      }, 100);
      return false;
    } else {
      radioValues[name] = clickedValue;
    }
  };

  const initRadio = ($radio) => {
    const name = $radio.attr('name');
    radioValues[name] =  $('[type=radio][name="' + name + '"  ]:checked').val();
    $radio.on('click', function () {
      radioClicked($(this));
    });

    events($radio)
  };

  $('.form-check.form-check-inline input[type=radio]').each(function () {
    initRadio($(this));
  });
};

export default ResetRadio;
