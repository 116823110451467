import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["showIcon", "hideIcon", "input"]

  toggle() {
    const type = this.inputTarget
      .getAttribute('type') === 'password' ?
      'text' : 'password';
    this.inputTarget.setAttribute('type', type);
    this.showIconTarget.classList.toggle('d-none');
    this.hideIconTarget.classList.toggle('d-none');
    
  }

}


