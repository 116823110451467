import StimulusFlatpickr from "stimulus-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";
import { english } from "flatpickr/dist/l10n/default.js";
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";

export default class extends StimulusFlatpickr {

  locales = {
    it: Italian,
    en: english
  }

  connect() {
    this.config = {
      allowInput: true,
      locale: ENVIRONMENT.lang,
      "plugins": [new confirmDatePlugin({})],
    };

    super.connect();
  }
}
