/* 
	Struttura html necessaria:
	tag contenitore del form deve avere classe "cf-block" e attributo "data-url"
	input che si compilerà con i dati ricevuti dalla chiamata ajax deve avere classe "cf-block__input"
	il bottone che all'evento click fa partire la chiamata ajax deve avere classe "cf-block__btn"
*/
import Swal from 'sweetalert2';


const FCblock = function(block) {
	this.defineRequired();
	this.elements(block);
	this.events();
	return this;
};

FCblock.prototype.defineRequired = function() {
	this.required = [
		'first_name',
		'last_name',
		'sex',
		'date_of_birth',
		'birth_city',
		'birth_province_region_state',
		'birth_country'
	];
};

FCblock.prototype.elements = function(block) {
	this.block = block;
	this.inputCf = this.block.find('.cf-block__input input');
	this.btn = this.block.find('.cf-block__btn');

	this.originalText = this.btn.text();
	this.object = this.block.data('object');
	this.url = this.block.data('url');
};
FCblock.prototype.events = function() {
	var self = this;
	this.btn.on('click', function(event) {
		return self.sendData(event);
	});
};

FCblock.prototype.checkIsValid = function() {
	let object = this.object;
	let isValid = true;
	let $fields;
	$.each(this.required, function(index, item) {
		$fields = $('[name="' + object + '[' + item + ']"]');
		if (item === 'sex') {
			isValid = false;
			$fields.each(function() {
				if ($(this).is(':checked')) { isValid = true; }
			});
		} else {
			if (!$fields.val()) { isValid = false; }
		}
		// se isValid è false anche per un solo campo esco dal ciclo
		return isValid;
	});

	return isValid;
};

FCblock.prototype.sendData = function(event) {
	const self = this;
	const url =  this.url;

	if (!this.checkIsValid()) {
		Swal.fire({
			title: "",
			text: i18n.t('it_fiscal_code_requirements'),
			type: 'warning',
		})
		return false;
	}

	$.ajax({
		url : url,
		data :  $('form').serialize(),
		dataType : 'html',
		success : function (data) {
			self.inputCf.val(data);
			self.btn.text(self.originalText);
		},
		error : function (error) {}
	});
	return event.preventDefault();
};

export default FCblock;