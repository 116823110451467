import { Controller } from "@hotwired/stimulus";
import 'bootstrap-table'
import 'bootstrap-table/dist/locale/bootstrap-table-it-IT.min'
import 'bootstrap-table/dist/extensions/export/bootstrap-table-export.min.js'
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control'
import 'bootstrap-table/dist/extensions/auto-refresh/bootstrap-table-auto-refresh'
// import 'bootstrap-table/dist/extensions/cookie/bootstrap-table-cookie'
import 'tableexport.jquery.plugin/libs/FileSaver/FileSaver.min'
import 'tableexport.jquery.plugin/tableExport.min'

export default class extends Controller {

  static targets = ['table', 'action_response']
  static values = {
    export: Boolean
  }

  connect() {
    let showExport;
    if (this.hasExportValue) {
      showExport = this.exportValue
    }
    else {
      showExport = true
    }
    $(this.tableTarget).bootstrapTable({
      pagination: true,
      search: true,
      locale: i18n.locale,
      searchHighlight: true,
      showSearchClearButton: true,
      showExport: showExport,
      showRefresh: true,
      filterControl: true,
      icons: {
        clearSearch: 'bi-x-lg'

      },
      buttonsClass: 'primary btn-xs',
      // cookie: true,
      // cookieIdTable: this.tableTarget.id
    })
    
  }

  disconnect() {
    $(this.tableTarget).bootstrapTable('destroy');
  }

  tableTargetDisconnected() {
    // const cookies = $(this.tableTarget).bootstrapTable('getCookies');
    // console.log(cookies)
    // Object.keys(cookies).forEach(key => {
    //   if (cookies[key] !== null) {  // Only process non-null values
    //     $(this.tableTarget).bootstrapTable('deleteCookie', key);
    //   }
    // });

  }

  performAction(e) {
    e.preventDefault()
    this.action_responseTarget.classList.remove('d-none')
    this.action_responseTarget.innerHTML = i18n.t('actions.performing')
    const $table = $(this.tableTarget);
    const selectedIds = $table.bootstrapTable('getSelections').map(row => parseInt(row.id));
  
    fetch(e.params.action, {
      method: e.params.method,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ ids: selectedIds })
    })
      .then(response => {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          return response.json().then(data => {
            const html = data.map(item => `<li>${item}</li>`).join('');
            this.action_responseTarget.innerHTML = `<ul>${html}</ul>`;
            $table.bootstrapTable('refresh');
          });
        } else if (contentType && contentType.includes('application/pdf')) {
          return response.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'invite_letters.pdf';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.action_responseTarget.classList.add('d-none');
            $table.bootstrapTable('refresh');
          });
        }
        
      
      
    })
    .catch((error) => {
      // Handle any errors here
    });
  }
}