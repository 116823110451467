import { Controller } from "@hotwired/stimulus";
import ResetRadio from '../modules/resetRadio';

export default class extends Controller {

  connect() {
    ResetRadio();
  }

}


