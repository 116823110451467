import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['collapsible']

  toggle(e) {
    e.preventDefault()
    this.collapsibleTarget.classList.toggle('show')
  }

}