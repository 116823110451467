import { Controller } from "@hotwired/stimulus";
import { Notification } from "bootstrap-italia"

export default class extends Controller {

  static targets = ["notification"]

  connect() {
    // console.log(this.notificationTarget)
    let options = {
      timeout: 10000
    }
    if (this.hasNotificationTarget) {
      const myNotification = new Notification(this.notificationTarget, options)      
      myNotification.show()
    }

  }

  dismiss() {
    if (this.hasNotificationTarget) {
      const myNotification = new Notification(this.notificationTarget)
      myNotification.hide()
    }
  }

}