import { Controller } from "@hotwired/stimulus";
import { ProgressDonut } from "bootstrap-italia"

export default class extends Controller {

  connect() {
    let value = this.element.dataset.bsValue
    let options = {}
    switch(true) {
      case (value <= 0.25):
        options = {
          color: "#F18F01"
        }
        break;
      case (value <= 0.5):
        options = {
          color: "#048BA8"
        }
        break;
      case (value <= 0.75):
        options = {
          color: "#2E4057"
        }
        break;
      case (value <= 1.0):
        options = {
          color: "#99C24D"
        }
        break;
      default:
        options = {}
    }
    new ProgressDonut(this.element, options)
  }

  disconnect() {
    ProgressDonut.getInstance(this.element).dispose()
  }

}