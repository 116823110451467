import { Controller } from "@hotwired/stimulus";
import FCblock from '../modules/fiscal-code';

export default class extends Controller {

  connect() {
    $('.cf-block').each(function () { new FCblock($(this)); }); // Modulo per calcolo codice fiscale
  }

}


