import { Controller } from '@hotwired/stimulus'
import { values } from 'lodash';
import Swiper from 'swiper'
import *  as SwiperModules from 'swiper/modules';
import MobileDetect from 'mobile-detect';


export default class extends Controller {

  static values = {
    slidesperview: Number,
    effect: String,
  }

  static targets = ['swiper', 'pagination', 'navigationnext', 'navigationprev']

  connect() {
    let md = new MobileDetect(window.navigator.userAgent);
    if (md.mobile()) {
      this.slidesperviewValue = 1
    }
    new Swiper(this.swiperTarget, {
      modules: [SwiperModules.Autoplay, SwiperModules.Navigation, SwiperModules.Scrollbar, SwiperModules.Pagination, SwiperModules.EffectCoverflow, SwiperModules.EffectFlip, SwiperModules.EffectCards, SwiperModules.EffectFade, SwiperModules.EffectCube, SwiperModules.EffectCreative],
      effect: this.effectValue,
      // autoplay: {
      //   pauseOnMouseEnter: true,
      // },
      loop: true,
      centeredSlides: true,
      slidesPerView: this.slidesperviewValue,
      navigation: {
        nextEl: this.navigationnextTarget,
        prevEl: this.navigationprevTarget,
      },
       coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      },
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      //   hide: true,
      // },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
        dynamicBullets: true,
      },
      cubeEffect: {
        slideShadows: false,
        shadow: false,
        shadowOffset: 20,
        shadowScale: 0.94,
      },
      creativeEffect: {
        prev: {
          shadow: true,
          translate: [0, 0, -800],
          rotate: [180, 0, 0],
        },
        next: {
          shadow: true,
          translate: [0, 0, -800],
          rotate: [-180, 0, 0],
        },
      },
    })
  }
}