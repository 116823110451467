import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {

  connect() {
    let options = {
      plugins: ['dropdown_input', 'remove_button'],
    }

    this.tomselect = new TomSelect(this.element, options);

    window.addEventListener("place_changed", (e) => {
      this.tomselect.sync(); // synchronise with the underlying SELECT
    }, false);

  }

}