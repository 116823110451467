
const getLabel = function(element) {
  const label = element.find('label');
  const textWrapper = label.length ? label : element;
  return textWrapper.text().replace('* ', '').replace('** ', '');
};


const findController = function(controlled) {
  const name = controlled.data('name');
  const controller = $('[name="' + name + '"][type!="hidden"]').eq(0);
  // label = controller.parents('.col-md-9').siblings('.col-md-3').find('label').text().replace('* ', '').replace('** ', '')
  const label = getLabel(controller.parents('.row.mb-3').find('label.col-sm-3'));
  // resituisce l'elemento a cui deve ancorarsi l'icona e l'etichetta da far vedere
  return {
    anchor: controller,
    label
  };
};

const msgDepends = ENVIRONMENT.lang === 'it' ? 'Dipende dal campo' : 'It depends on';

const addIcon = function($element, controller) {
  let icon = undefined;
  let _this = undefined;
  let $container = $('.l-main');
  $container = $(window).width() < 992 ? $('body') : $('.l-main');
  // prendo i child diretti per i depends_on dentro ad altri depends_on
  return $element.find(' > .row.mb-3 > label.col-sm-3, .ep-auto-complete-module > > .row.mb-3 > label.col-sm-3').each(function() {
    _this = $(this);
    icon = $(document.createElement('a'));
    icon
      .addClass('bi bi-link-45deg')
      .attr('title', '<span class="depend-tooltip">' + msgDepends + ': <strong>' + controller.label.toUpperCase() + '</strong></span>')
      .tooltip({placement: 'bottom', html: true})
      .on('click', function(e) {
        e.preventDefault();
        const offsetTop = $(window).width() < 992 ? -100 : -60;
        //console.log offsetTop
        return $container.scrollTo(controller.anchor, 300, { offset: {
          top: offsetTop,
          left: 0
        }
      }
        );
    });
    return _this.prepend(icon).addClass('has-depends-icon');
  });
};

const addAnchor = function($element) {
  const controller = findController($element);
  //console.log controller
  return addIcon($element, controller);
};

export default addAnchor;
